// This file can be replaced during build by using the `fileReplacements` array.

export const environment = {
  production: false,
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyCb5p5xfmAFmeQLTrJsYLJxQ5bD78NdqT4',
    authDomain: 'logichat-dev.firebaseapp.com',
    projectId: 'logichat-dev',
    storageBucket: 'logichat-dev.appspot.com',
    messagingSenderId: '569130446042',
    appId: '1:569130446042:web:9598eff6aea57af09409e3',
    measurementId: 'G-5M1S5MVDEV',
  },
  stripe: {
    token:
      'pk_test_51JdeYvIQ1KfxqwvpXVITP1XR7rh3afOCbyqDNTAWRyyNkggBrzRukcD8IgfQPApJBav8PVlry5MBlfsQV72ZFrgj00tLIuOXG7',
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
